<template>
  <div class="card">
    <div class="card-header">
      <span>{{ $t("all.filterByAddedDate") }} :</span>
      <a-date-picker
        class="ml-2"
        :placeholder="$t('recette.selectionnerDate')"
        format="DD/MM/YYYY"
        @change="
          (val) => {
            handleFilterChange(val);
          }
        "
      />
      <div style="margin-bottom: 20px; float: right">
        <json-csv
          class="ant-btn"
          style="width: 100%"
          :fields="fields"
          :labels="labels"
          :data="csvTable"
          :name="'Caisse avance.csv'"
          ><a-icon
            class="anticon mt-2"
            style="color: green"
            type="file-excel"
          />
          <span>CSV</span>
        </json-csv>
      </div>
      <div style="margin-right: 20px; margin-bottom: 20px; float: right">
        <a-button type="primary" style="width: 100%" @click="imprimer"
          ><a-icon type="printer" />{{ $t("action.imprimer") }}</a-button
        >
      </div>
    </div>
    <div class="card-body">
      <a-button type="primary" class="mb-2" @click="link">
        <a-icon type="plus-circle" theme="twoTone" @click="link" />{{
          $t("avance.ajoutAvance")
        }}
      </a-button>
      <a-table
        @change="tableChanged"
        rowKey="_id"
        :pagination="true"
        :data-source="data"
        :columns="columns"
        :loading="tableLoading"
        :scroll="{ x: 'max-content' }"
      >
        <template slot="date" slot-scope="text">
          {{ moment(text).format("DD/MM/YYYY") }}
        </template>

        <div slot="nomClient" slot-scope="text">
          <a-avatar
            size="large"
            slot="avatar"
            v-if="text.photo"
            :src="settings.base_url + '/' + text.photo"
          />
          <a-avatar
            v-else
            size="large"
            slot="avatar"
            style="color: #f56a00; background-color: #fde3cf"
          >
          </a-avatar>
          <span>{{ text.father.lastName + " " + text.father.firstName }}</span>
        </div>
        <template slot="montantChequeEspece" slot-scope="text, record">
          <p v-if="record.used">--</p>
          <p v-else>{{ text }}</p>
        </template>

        <template slot="totalAvance" slot-scope="text">
          <strong>
            <a-tag color="red" v-if="text < 0">{{ text }}</a-tag>
            <p v-else>{{ text }}</p>
          </strong>
        </template>
        <template slot="modePaiement" slot-scope="text, record">
          <a-button
            icon="search"
            type="primary"
            block
            v-if="record.cheque"
            @click="chequeDetailsModal(record)"
          >
            {{
              text == "espéce"
                ? $t("recette.espece")
                : text == "chèque"
                ? $t("recette.cheque")
                : text == "Chèque et espéce"
                ? $t("recette.chequeEtEspece")
                : text
            }}
          </a-button>
          <div v-else>
            {{
              text == "espéce"
                ? $t("recette.espece")
                : text == "chèque"
                ? $t("recette.cheque")
                : text == "Chèque et espéce"
                ? $t("recette.chequeEtEspece")
                : text
            }}
          </div>
        </template>
        <template slot="annulation" slot-scope="text, record">
          <a-popconfirm
            :title="$t('all.sureToCancelAlert')"
            @confirm="() => annulation(record)"
          >
            <a-button type="danger">
              <a-icon type="delete" href="javascript:;" />{{
                $t("action.annuler")
              }}
            </a-button>
          </a-popconfirm>
        </template>
        <a-button
          slot="printOne"
          type="primary"
          slot-scope="text, record"
          @click="() => printOne(record)"
        >
          <a-icon type="file-pdf" />{{ $t("action.imprimer") }}
        </a-button>
        <div
          slot="filterDropdown"
          slot-scope="{
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            column,
          }"
          style="padding: 8px"
        >
          <a-input
            v-ant-ref="(c) => (searchInput = c)"
            :placeholder="`chercher ${column.title}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block"
            @change="
              (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
            "
            @pressEnter="
              () => handleSearch(selectedKeys, confirm, column.dataIndex)
            "
          />
          <a-button
            type="primary"
            icon="search"
            size="small"
            style="width: 90px; margin-right: 8px"
            @click="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
            >{{ $t("recette.chercher") }}</a-button
          >
          <a-button
            size="small"
            style="width: 90px"
            @click="() => handleReset(clearFilters)"
            >{{ $t("action.reinitialiser") }}</a-button
          >
        </div>
        <a-icon
          slot="filterIcon"
          slot-scope="filtered"
          type="search"
          :style="{ color: filtered ? '#108ee9' : undefined }"
        />
        <template slot="customRender" slot-scope="text, record, index, column">
          <span v-if="searchText && searchedColumn === column.dataIndex">
            <template
              v-for="(fragment, i) in text
                .toString()
                .split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
            >
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
                >{{ fragment }}</mark
              >
              <template v-else>{{ fragment }}</template>
            </template>
          </span>
          <template v-else>{{ text }}</template>
        </template>
      </a-table>
    </div>
    <a-modal
      v-model="chequeVisible"
      :title="$t('recette.detailsCheque')"
      @ok="handleOk"
    >
      <div v-if="activeCheque.modeName === 'Chèque et espéce'">
        <a-descriptions>
          <a-descriptions-item span="2">
            <template v-slot:label>
              <span class="ant-descriptions-item-label"
                >{{ $t("recette.montantCheque") }} :</span
              >
            </template>
            {{ activeCheque.montantCheque }}
          </a-descriptions-item>
          <a-descriptions-item span="3">
            <template v-slot:label>
              <span class="ant-descriptions-item-label"
                >{{ $t("recette.montantEspece") }} :</span
              >
            </template>
            {{ activeCheque.montantEspece }}
          </a-descriptions-item>
        </a-descriptions>
        <a-divider></a-divider>
      </div>
      <a-descriptions>
        <a-descriptions-item span="2">
          <template v-slot:label>
            <span class="ant-descriptions-item-label">{{
              $t("recette.numero")
            }}</span>
          </template>
          {{ activeCheque.num }}
        </a-descriptions-item>
        <a-descriptions-item span="3">
          <template v-slot:label>
            <span class="ant-descriptions-item-label">{{
              $t("recette.titulaire")
            }}</span>
          </template>
          {{ activeCheque.titulaire }}
        </a-descriptions-item>
        <a-descriptions-item span="4">
          <template v-slot:label>
            <span class="ant-descriptions-item-label">{{
              $t("recette.banque")
            }}</span>
          </template>
          <a-tag color="blue">
            {{ activeCheque.bank }}
          </a-tag>
        </a-descriptions-item>

        <a-descriptions-item span="4">
          <template v-slot:label>
            <span class="ant-descriptions-item-label">{{
              $t("recette.dateEcheance")
            }}</span>
          </template>
          {{
            activeCheque.dateEchance
              ? moment(activeCheque.dateEchance).format("DD/MM/YYYY")
              : "--"
          }}
        </a-descriptions-item>
      </a-descriptions>
      <a-alert
        :message="
          activeCheque.dateEchance
            ? moment(activeCheque.dateEchance).fromNow()
            : '--'
        "
        type="info"
        :close-text="$t('recette.fermer')"
      />
    </a-modal>
  </div>
</template>
<script>
/* eslint-disable */
import apiClient from "@/services/axios";
import moment from "moment";
import JsPDF from "jspdf";
import "jspdf-autotable";
import { mapState } from "vuex";
import JsonCsv from "vue-json-csv";
const recipetTypes = {};
var datePicker = "";
/*
data : {
    nomClient,
    enfant,
    date,
    montant
    tags:Array of keysOf(recipeTypes or/and userDefinedRecipes)
}
*/

export default {
  components: {
    JsonCsv,
  },
  async created() {
    this.tableLoading = true;
    await apiClient
      .post("/receipt_types/filter", {
        query: { status: "active" }, //schoolarYear: this.settings.activeSchoolarYear
      })
      .then((res) => {
        res.data.map((recipe) => (this.recipetTypes[recipe._id] = recipe.name));
      });

    await apiClient
      .get("/avance")
      .then((res) => {
        this.data = res.data;
        this.rowData = res.data;
        this.filtredTable = res.data;
      })
      .catch((e) => {
        console.error(e.stack);
        this.$message.warning(this.$t("warning.connectionProblem"));
      })
      .finally(() => (this.tableLoading = false));
  },
  computed: {
    ...mapState(["settings"]),
    csvTable() {
      const data = this.filtredTable.map((item) => {
        let returnValue = { ...item };
        returnValue.modePaiement = item.receiptType.name;
        returnValue.nomClient =
          item.parent.father.lastName + " " + item.parent.father.firstName;
        returnValue.NumCheque = item.cheque ? item.cheque.num : "--";
        returnValue.date = moment(item.createdAt).format("DD/MM/YYYY");
        return returnValue;
      });
      return data;
    },
  },
  data() {
    return {
      labels: {
        nomclient: this.$t("recette.nom"),
        modePaiement: this.$t("recette.modePaiement"),
        montantEspece: this.$t("recette.montantEspece"),
        montantCheque: this.$t("recette.montantCheque"),
        totalAvance: this.$t("avance.montantAvance"),
        date: this.$t("punition.dateAjout"),
        NumCheque: this.$t("recette.numCheque"),
      },
      fields: [
        "nomClient",
        "modePaiement",
        "montantEspece",
        "montantCheque",
        "totalAvance",
        "date",
        "NumCheque",
      ],
      recipetTypes,
      datePicker,
      data: [],
      rowData: [],
      cacheData: [],
      chequeVisible: false,
      activeCheque: {},
      filters: {
        recette: false,
        recette_val: null,
        date: false,
        date_val: null,
      },
      columns: [
        {
          title: this.$t("recette.nom"),
          dataIndex: "parent",
          key: "nomClient",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "nomClient",
          },

          onFilter: (value, record) =>
            (
              record.parent.father.lastName +
              " " +
              record.parent.father.firstName
            )
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("recette.modePaiement"),
          dataIndex: "receiptType.name",
          key: "modePaiement",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "modePaiement",
          },
          onFilter: (value, record) => {
            console.log(value, record);
            if (record.cheque) {
              return (
                record.receiptType.name
                  .toLowerCase()
                  .includes(value.toLowerCase()) ||
                record.cheque.num.toLowerCase().includes(value.toLowerCase()) ||
                record.cheque.bank
                  .toLowerCase()
                  .includes(value.toLowerCase()) ||
                record.cheque.titulaire
                  .toLowerCase()
                  .includes(value.toLowerCase()) ||
                record.cheque.dateEchance
                  .toLowerCase()
                  .includes(value.toLowerCase())
              );
            } else {
              return record.receiptType.name
                .toLowerCase()
                .includes(value.toLowerCase());
            }
          },
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("recette.typeReglement"),
          dataIndex: "typeReglement",
          key: "typeReglement",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          onFilter: (value, record) => {
            return record.typeReglement
              .toLowerCase()
              .includes(value.toLowerCase());
          },
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("recette.montantEspece"),
          dataIndex: "montantEspece",
          key: "montantEspece",
          scopedSlots: {
            customRender: "montantChequeEspece",
          },
          sorter: (a, b) => a.montantEspece - b.montantEspece,
        },
        {
          title: this.$t("recette.montantCheque"),
          dataIndex: "montantCheque",
          key: "montantCheque",
          scopedSlots: {
            customRender: "montantChequeEspece",
          },
          sorter: (a, b) => a.montantCheque - b.montantCheque,
        },
        {
          title: this.$t("avance.montantAvance"),
          dataIndex: "totalAvance",
          key: "totalAvance",
          scopedSlots: {
            customRender: "totalAvance",
          },
          sorter: (a, b) => a.totalAvance - b.totalAvance,
        },
        {
          title: this.$t("punition.dateAjout"),
          dataIndex: "createdAt",
          key: "date",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "date",
          },
          onFilter: (value, record) =>
            record.date.includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("recette.impression"),
          dataIndex: "print",
          key: "print",
          fixed: "right",
          scopedSlots: {
            customRender: "printOne",
          },
        },
        {
          title: this.$t("recette.annulation"),
          dataIndex: "annulation",
          key: "annulation",
          fixed: "right",
          scopedSlots: {
            customRender: "annulation",
          },
        },
      ],
      tableLoading: true,
      filtredTable: [],
    };
  },
  methods: {
    link() {
      this.$router.push({ path: "addAvance" });
    },
    chequeDetailsModal(record) {
      console.log(record);

      let recordData = JSON.parse(JSON.stringify(record));
      this.activeCheque = recordData.cheque;
      this.activeCheque.modeName = recordData.receiptType.name;
      this.activeCheque.montantCheque = recordData.montantCheque;
      this.activeCheque.montantEspece = recordData.montantEspece;

      this.chequeVisible = true;
    },
    handleOk(e) {
      this.chequeVisible = false;
    },
    moment,
    tableChanged(pagination, filters, sorter, extra) {
      this.filtredTable = extra.currentDataSource;
    },
    annulation(record) {
      apiClient
        .delete("/avance/" + record._id)
        .then((res) => {
          this.data = this.data.filter((e) => e._id !== record._id);
          this.rowData = this.data;
          this.filtredTable = this.data;
        })
        .catch((e) => {
          console.log(e);
          this.$message.error(this.$t("avance.impoAnnulerAvance"));
        });
    },
    imprimer() {
      this.$gtag.event("Imp Caisse avance", {
        event_category: "Impression PDF",
        event_label: "comptabilite:CaisseAvance",
        value: 1,
      });

      let arrayOfImages = this.settings.image;
      for (var i = 0; i < arrayOfImages.length; i++) {
        if (this.settings.activeBuilding === arrayOfImages[i].db) {
          var imgData = new Image();
          imgData.src = arrayOfImages[i].logo;
        }
      }
      const doc = new JsPDF();
      doc.setFont("Amiri");
      var date = new Date();
      let filterText =
        this.datePicker !== "" ? " le " + this.datePicker : " Tous les mois";
      var titre = "Caisse avance ";
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      doc.setFontSize(15);
      doc.text(80, 60, titre);
      doc.text(89, 70, filterText);
      doc.setFontSize(10);
      doc.addImage(imgData, "JPEG", 8, 8, 40, 40);
      doc.setFontSize(12);
      doc.text(150, 40, date.toLocaleDateString("fr-FR", options));
      var arr = [];
      const array = [];
      let sommeAvance = 0;
      let sommeEsp = 0;
      let sommeChq = 0;
      for (let i = 0; i < this.filtredTable.length; i++) {
        let colorFill = this.filtredTable[i].used
          ? [255, 204, 204]
          : [255, 255, 255];
        arr.push({
          content:
            this.filtredTable[i].parent.father.lastName +
            " " +
            this.filtredTable[i].parent.father.firstName,
          styles: { fillColor: colorFill },
        });
        let modePaiement = this.filtredTable[i].receiptType.name;
        arr.push({
          content: modePaiement,
          styles: { fillColor: colorFill },
        });
        arr.push({
          content: this.filtredTable[i].typeReglement,
          styles: { fillColor: colorFill },
        });
        arr.push({
          content: moment(this.filtredTable[i].createdAt).format("DD/MM/YYYY"),
          styles: { fillColor: colorFill },
        });
        arr.push({
          content: this.filtredTable[i].cheque
            ? this.filtredTable[i].cheque.num
            : "--",
          styles: { fillColor: colorFill },
        });

        arr.push({
          content: this.filtredTable[i].montantCheque,
          styles: { fillColor: colorFill },
        });
        sommeChq += this.filtredTable[i].montantCheque
          ? parseFloat(this.filtredTable[i].montantCheque)
          : 0;
        arr.push({
          content: this.filtredTable[i].montantEspece,
          styles: { fillColor: colorFill },
        });
        sommeEsp += this.filtredTable[i].montantEspece
          ? parseFloat(this.filtredTable[i].montantEspece)
          : 0;

        arr.push({
          content: this.filtredTable[i].totalAvance,
          styles: { fillColor: colorFill },
        });
        sommeAvance += parseFloat(this.filtredTable[i].totalAvance);

        array.push(arr);
        arr = [];
      }

      doc.autoTable({
        startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : 80,
        styles: { font: "Amiri" },
        head: [
          [
            "Nom client",
            "Mode paiement",
            "Type de réglement",
            "Date d'ajout",
            "Numéro du chèque",
            "Montant chèque",
            "Montant espéce",
            "Montant avance",
          ],
        ],
        body: array,
        foot: [
          [
            { colSpan: 5, content: "Total", styles: { halign: "center" } },
            sommeChq.toFixed(2),
            sommeEsp.toFixed(2),
            sommeAvance.toFixed(2),
          ],
        ],
      });
      //page numbering
      const pages = doc.internal.getNumberOfPages();
      const pageWidth = doc.internal.pageSize.width;
      const pageHeight = doc.internal.pageSize.height;
      doc.setFontSize(8); //Optional

      for (let j = 1; j < pages + 1; j++) {
        let horizontalPos = pageWidth / 2;
        let verticalPos = pageHeight - 10;
        doc.setPage(j);
        doc.text(`Page ${j} / ${pages}`, horizontalPos, verticalPos, {
          align: "center",
        });
      }
      //end page numbering
      doc.save("Caisse avance.pdf");
    },
    printOne(record) {
      this.$gtag.event("Imp avance de client", {
        event_category: "Impression PDF",
        event_label: "comptabilite:CaisseAvance",
        value: 1,
      });

      const tva = 0.07;
      console.log(record);
      let arrayOfImages = this.settings.image;
      for (var i = 0; i < arrayOfImages.length; i++) {
        if (this.settings.activeBuilding === arrayOfImages[i].db) {
          var imgData = new Image();
          imgData.src = arrayOfImages[i].logo;
        }
      }
      const doc = new JsPDF();
      doc.setFont("Amiri");
      var date = new Date();

      var titre = "Reçu d'avance";
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      doc.setFontSize(17);
      let clientName =
        record.parent.father.lastName +
        " " +
        record.parent.father.firstName +
        doc.text(80, 60, titre);
      doc.text(20, 70, "Nom et Prénom : " + clientName);
      doc.setFontSize(10);
      doc.addImage(imgData, "JPEG", 8, 8, 40, 40);
      doc.setFontSize(12);
      doc.text(150, 40, date.toLocaleDateString("fr-FR", options));
      const array = [];

      var arr = [];
      let colorFill = record.used ? [255, 204, 204] : [255, 255, 255];
      arr.push({
        content: clientName,
        styles: { fillColor: colorFill },
      });
      let modePaiement = record.receiptType.name;
      arr.push({
        content: modePaiement,
        styles: { fillColor: colorFill },
      });
      arr.push({
        content: record.typeReglement,
        styles: { fillColor: colorFill },
      });
      arr.push({
        content: moment(record.createdAt).format("DD/MM/YYYY"),
        styles: { fillColor: colorFill },
      });
      arr.push({
        content: record.cheque ? record.cheque.num : "--",
        styles: { fillColor: colorFill },
      });

      arr.push({
        content: record.montantCheque,
        styles: { fillColor: colorFill },
      });
      arr.push({
        content: record.montantEspece,
        styles: { fillColor: colorFill },
      });

      arr.push({
        content: record.totalAvance,
        styles: { fillColor: colorFill },
      });

      array.push(arr);

      doc.autoTable({
        styles: {
          lineWidth: 0.2,
          valign: "middle",
          halign: "center",
          fontStyle: "bold",
          font: "Amiri",
        },
        startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : 80,
        head: [
          [
            "Nom client",
            "Mode paiement",
            "Type de réglement",
            "Date d'ajout",
            "Numéro du chèque",
            "Montant chèque",
            "Montant espéce",
            "Montant avance",
          ],
        ],
        body: array,
      });
      doc.save(titre + ".pdf");
    },
    handleFilterChange(value) {
      let newData = [];

      this.datePicker = moment(value).format("DD/MM/YYYY");
      if (!moment(value).isValid()) newData = [...this.rowData];
      else
        newData = this.rowData.filter(
          (elem) =>
            moment(elem.createdAt).format("DD/MM/YYYY") ==
            moment(value).format("DD/MM/YYYY")
        );

      this.data = newData;
      this.filtredTable = newData;
    },

    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      this.searchText = selectedKeys[0];
      this.searchedColumn = dataIndex;
    },
    handleReset(clearFilters) {
      clearFilters();
      this.searchText = "";
    },

    frMonths(s) {
      const months = {};
      const monthsArr = moment.months();
      for (let i = 0; i < monthsArr.length; i++) months[i + 1] = monthsArr[i];
      return months[parseInt(s.split("-")[1])];
    },
  },
};
</script>
